import React from "react"
import { Link } from "gatsby"
import { Trans, t } from "@lingui/macro"
import { I18nProvider, I18n } from "@lingui/react"
import catalogEs from "../locales/es/messages"
import catalogEn from "../locales/en/messages"
import catalogBr from "../locales/br/messages"
import catalogFr from "../locales/fr/messages"
import catalogIt from "../locales/it/messages"
import catalogDe from "../locales/de/messages"

const year = new Date().getFullYear()

export default props => (
  <>
    <I18nProvider
      language={props.lang}
      catalogs={{
        es: catalogEs,
        en: catalogEn,
        it: catalogIt,
        de: catalogDe,
        br: catalogBr,
        fr: catalogFr,
      }}
    >
    <footer className="footer">
      <div className="container mt-5 text-center">
        <div style={{ display: "inline-block" }}>
          <I18n>
            {({ i18n }) => (
              <Link
                to={i18n._(t`/home`)}
                title={i18n._(t`home`)}
                className="nav-link"
                activeStyle={{ color: "black" }}
                style={{ textDecoration: "none" }}
              >
                <small className="copyright">Luis Juarros &copy; {year}</small>
              </Link>
            )}
          </I18n>
        </div>
        &nbsp;
        <div style={{ display: "inline-block" }}>
          <I18n>
            {({ i18n }) => (
              <Link
                to={i18n._(t`/politica-de-cookies`)}
                title={i18n._(t`Política de cookies`)}
                rel="nofollow"
                className="nav-link"
                activeStyle={{ color: "black" }}
                style={{ textDecoration: "none" }}
              >
                <small className="copyright">
                  <Trans>Política de cookies</Trans>
                </small>
              </Link>
            )}
          </I18n>
        </div>
        &nbsp;
        <div style={{ display: "inline-block" }}>
          <I18n>
            {({ i18n }) => (
              <Link
                to={i18n._(t`/politica-de-privacidad`)}
                title={i18n._(t`Política de privacidad`)}
                rel="nofollow"
                className="nav-link"
                activeStyle={{ color: "black" }}
                style={{ textDecoration: "none" }}
              >
                <small className="copyright">
                  <Trans>Política de privacidad</Trans>
                </small>
              </Link>
            )}
          </I18n>
        </div>
        &nbsp;
        <div style={{ display: "inline-block" }}>
          <I18n>
            {({ i18n }) => (
              <Link
                to={i18n._(t`/aviso-legal`)}
                title={i18n._(t`Aviso legal`)}
                rel="nofollow"
                className="nav-link"
                activeStyle={{ color: "black" }}
                style={{ textDecoration: "none" }}
              >
                <small className="copyright">
                  <Trans>Aviso legal</Trans>
                </small>
              </Link>
            )}
          </I18n>
        </div>
      </div>
    </footer>
    </I18nProvider>
  </>
)
